import React from 'react'

// material
import { alpha, styled } from '@mui/material/styles'
import { AppBar, Box, Breadcrumbs, IconButton, Link, Toolbar, useMediaQuery } from '@mui/material'

//
import AccountPopover from './AccountPopover'
import NotificationsPopover from './NotificationsPopover'
import useCollapseDrawer from 'hooks/useCollapseDrawer'
import { MenuFill } from 'assets/MenuFill';
import { useAppSelector } from 'hooks/useAppRedux';
import { getPageTitle } from 'store/slices/settings';
import InsightsPopover from './InsightsPopover'
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 255
const DRAWER_WIDTH_COLLAPSED = 102
const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 92

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72), ///alpha(theme.palette.background.default, 0.72),
    marginLeft: '300px',
    //[theme.breakpoints.up('lg')]: {
    //  width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    //}
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    //minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
    //    minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
    //'@media (max-width:1300px)': {
    //    minHeight: APPBAR_MOBILE,
    //}
}))

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
    onOpenSidebar: VoidFunction
    title?: string
    defaultTitle?: string
}

export default function DashboardNavbar({ onOpenSidebar, title, defaultTitle }: DashboardNavbarProps) {

    const { collapseClick } = useCollapseDrawer()
    const hiddenUp = useMediaQuery((theme: any) => theme.breakpoints.up('md')) as boolean
    const sidebarWidth = hiddenUp ? (collapseClick ? DRAWER_WIDTH_COLLAPSED : DRAWER_WIDTH) : 0

    const pageTitle = useAppSelector(getPageTitle)

    return (
        <RootStyle sx={{
           /// zIndex: 999999,
            width: { md: `calc(100% - ${sidebarWidth + 1}px)` }
        }}>
            <ToolbarStyle>
                {!hiddenUp && (
                    <IconButton
                        onClick={onOpenSidebar}
                        sx={{ mr: 1, color: 'text.primary' }}
                        size='large'
                    >
                        <MenuFill  />
                    </IconButton>
                )}
                <Breadcrumbs aria-label='breadcrumb'>
                    <Link
                        color='inherit'
                        href='#'
                    >
                        { title || (pageTitle || (defaultTitle || '')) }&nbsp;&nbsp;&nbsp;
                    </Link>
                </Breadcrumbs>
                <Box sx={{ flexGrow: 1 }} />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        '& > *:not(:first-of-type)': {
                            ml: { xs: 1.5, sm: 2, lg: 3 },
                        },
                    }}
                >
                    {/* <Searchbar/> */}
                    <InsightsPopover />
                    <NotificationsPopover />
                    <AccountPopover />
                </Box>
            </ToolbarStyle>
        </RootStyle>
    )
}
