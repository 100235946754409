import { createSlice } from '@reduxjs/toolkit'
import axiosInstance, { getSandboxInstance, responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { isSandBox } from '../settings'
import { format, parseISO, startOfDay, endOfDay, addMinutes } from 'date-fns'
import { filter, find } from 'lodash'
import { reportError } from 'utils/errorReport'
import { RootState } from 'store'
import axios from 'axios';
import { sortByCaseSensitive } from 'utils/string';
import { createSelector } from 'reselect';
import { Initiative } from '../../../@types/initiative';
import { filterEmpty } from '../../../utils/array'

const initialState = {
    isLoading: false,
    error: false,
    data: {},
    filtersData: {
        initiatives: [],
        companyStrategy: [],
        phase: [],
        owner: [],
        changeType: [],
        activities: [],
        stakeholders: [],
        contact: [],
        partnerImpactScales: [],
    }
}

const slice = createSlice({
    name: 'resourcingChart',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.error = false
        },
        stopLoading(state) {
            state.isLoading = false
            state.error = false
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getResourcingChartSuccess(state, action) {
            state.data = action.payload
        },

        setResourcingChartFiltersData(state, action) {
            state.filtersData = action.payload
        },
    },
})

export default slice.reducer

let bubbleAxiosSource = axios.CancelToken.source()

export function getResourcingChart(startDate: Date | null, endDate: Date | null) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.getResourcingChartSuccess({}))
        dispatch(slice.actions.startLoading())
        bubbleAxiosSource.cancel()
        bubbleAxiosSource = axios.CancelToken.source()
        try {
            const response = await (isSandBox(getState()) ? await getSandboxInstance() : axiosInstance).get('/api/Report/BubbleData', {
                params: {
                    impactFromDate: format(startDate || new Date(), 'y-MM-d'),
                    impactToDate: format(endDate || new Date(), 'y-MM-d'),
                    useDefaultDiv: false,
                },
                cancelToken: bubbleAxiosSource.token
            })
            const data = responseData(response.data) || {}
            const hierarchyItems = data?.hierarchyItems || {}

            const res: any[] = []
            sortByCaseSensitive<Record<string, any>[]>(hierarchyItems?.division || [], 'Name').forEach((i: Record<string, any>) => {
                res.push({ Id: i.Id, Name: i.Name, value: i })
                const subDivisions = filter(hierarchyItems.subDivision, { ParentCompanyHierarchyItemId: i.Id }) || []
                sortByCaseSensitive(subDivisions, 'Name').forEach((sub: any) => {
                    res.push({ Id: sub.Id, title: sub.Name, value: sub })
                    const teams = filter(hierarchyItems.team, { ParentCompanyHierarchyItemId: sub.Id }) || []
                    sortByCaseSensitive(teams, 'Name').forEach((team: any) => {
                        res.push({ Id: team.Id, title: team.Name, value: team })
                    })
                })
            })

            const initiatives = new Set()
            const companyStrategy = new Set()
            const phase = new Set()
            const owner = new Set()
            const changeType = new Set()
            const activities = new Set()
            const stakeholders = new Set()
            const contact = new Set()
            const partnerImpactScales = new Set()

            const bubbles = (data?.bubbles||[]).map((bubble: Record<string, any>) => {
                if ((bubble?.ChangeResources||[]).length === 0) {
                    return null;
                }
                const { Impacts = [], ...rest } = bubble
                const ChangeResources = (bubble?.ChangeResources||[])
                    .map((i: any) => ({
                        ...i,
                        DivisionName: find(res, { Id: i?.Division })?.Name || ''
                    }))

                return {
                    ...rest,
                    ChangeResources,
                    Impacts: Impacts.map((i: any) => {
                        const impact = {
                            ...i,
                            ...rest,
                            From: addMinutes(startOfDay(parseISO(i.ImpactFromDate)), 10),
                            To: addMinutes(endOfDay(parseISO(i.ImpactToDate)), -10),
                            Division: i.DivisionNames,
                            Team: i.TeamNames,
                            Initiatives: bubble.InitiativeName,
                            ChangeType: bubble?.ChangeTypes || [],
                            Contact: bubble?.ContactName || '',
                            Phase: bubble?.InitiativePhaseName || '',
                            DivisionStrategy: bubble?.DivisionStrategy || [],
                            ChangeResources,
                        }
                        initiatives.add(impact.Initiatives)
                        impact.CompanyStrategy?.forEach(companyStrategy.add.bind(companyStrategy))
                        phase.add(impact.Phase)
                        owner.add(impact.Owner)
                        impact.ChangeType.forEach(changeType.add.bind(changeType))
                        contact.add(impact.Contact)
                        impact.Activities.forEach(activities.add.bind(activities))
                        impact.Stakeholders.forEach(stakeholders.add.bind(stakeholders))
                        partnerImpactScales.add(impact?.PartnerImpactScaleId)
                        return impact
                    }),
                }
            }).filter((i: any) => i)

            dispatch(slice.actions.setResourcingChartFiltersData({
                initiatives: Array.from(initiatives).sort(),
                companyStrategy: Array.from(companyStrategy).sort(),
                phase: Array.from(phase).sort(),
                owner: Array.from(owner).sort(),
                changeType: Array.from(changeType).sort(),
                activities: Array.from(activities).sort(),
                stakeholders: Array.from(stakeholders).sort(),
                contact: Array.from(contact).sort(),
                partnerImpactScales: Array.from(partnerImpactScales).filter((i) => i),
            }))

            dispatch(slice.actions.getResourcingChartSuccess({ ...data, bubbles }))
            dispatch(slice.actions.stopLoading())
        } catch (error) {
            if (axios.isCancel(error)) {
                return;
            }
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}

export const getResourcingFiltersData = createSelector(
    (state: RootState) => state.reports.resourcingChart,
    (resourcingChart): Record<string, any> => resourcingChart.filtersData
)
const roles = [
    { id: 1, name: 'Business Representative' },
    { id: 2, name: 'Change Representative' },
    { id: 3, name: 'Subject Matter Expert' },
]

export const changeInitiativeItem = (initiative: Initiative) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const reportData = getState().reports.resourcingChart.data

        dispatch(slice.actions.getResourcingChartSuccess({
            ...reportData,
            bubbles: filterEmpty((reportData?.bubbles || []).map((i: Record<string, any>) => {
                if (i.InitiativeId === initiative.Id) {
                    return {
                        ...i,
                        ChangeResources: initiative.ChangeResources.map((j: any) => ({
                            ...j,
                            InitiativeId: initiative.Id,
                            RepCatType: find(roles, { id: j.RepCatType }) || {
                                Id: j.RepCatType,
                                Name: 'Unknown'
                            },
                            User: {
                                Id: "00000000-0000-0000-0000-000000000000",
                                Name: j.NameOfUser
                            }
                        }))
                    }
                }
                return i
            }))
        }))
    }
}
