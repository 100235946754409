import { intersection, isEmpty, some } from 'lodash'
import { BaseModel } from '../@types/base';
import { EditableFieldTree } from '../@types/initiative';

export const applyFilters = (items: any[], filterFuncs: any[]) => items.filter((item: any) => filterFuncs.every((f) => f(item)))


export const applySomeFilters = (items: any[], filterFuncs: any[]) => items.some((item: any) => filterFuncs.every((f) => f(item)))


export const filterEmployeeImpactBasic = (items: Record<string, any>[]) =>
    filterImpactBasic((items || []).filter((i: Record<string, any>) => i?.ImpactType === 'Employee'))


export const filterImpactBasicFunc = (item: Record<string, any>) => Boolean(item?.IsScenario) === false
export const filterImpactTypeFunc = (impactType: string) => (item: Record<string, any>) => item?.ImpactType === impactType


export const filterImpactBasic = (items: Record<string, any>[]) =>
    (items || []).filter(filterImpactBasicFunc)
// .filter((i: Record<string, any>) => i?.ImpactLevel > 0)


export const initiativesFilters = (
    filterValues: Record<string, any>,
    editableFields: EditableFieldTree[],
) => {
    const {
        initiatives,
        divisionInitiative,
        divisionStrategy,
        owner,
        companyStrategy,
        phase,
        changeType,
        contact,
        ResourcingStrategy,
        tags
    } = filterValues || {}

    const filterFuncs: Array<any> = []
    if (!isEmpty(initiatives)) {
        filterFuncs.push((i: any) => initiatives.includes(i?.Initiatives||i?.InitiativeName))
    }
    if (!isEmpty(owner)) {
        filterFuncs.push((i: any) => owner.includes(i?.Owner))
    }
    if (!isEmpty(phase)) {
        filterFuncs.push((i: any) => phase.includes(i?.Phase))
    }
    if (!isEmpty(contact)) {
        filterFuncs.push((i: any) => contact.includes(i?.Contact))
    }

    if (!isEmpty(changeType)) {
        filterFuncs.push((i: any) => (i?.ChangeType || []).some((v: string) => changeType.includes(v)))
    }
    if (!isEmpty(companyStrategy)) {
        // Strategy
        filterFuncs.push((i: any) => (i?.CompanyStrategy || i?.Strategy || []).some((v: string) => companyStrategy.includes(v)))
    }

    // DivisionForDivisionStrategy
    if (!isEmpty(divisionStrategy)) {
        filterFuncs.push((i: any) => (i?.DivisionThemes || (i?.DivisionThemes || [])).some((v: string) => some(divisionStrategy, { Id: v })))
    }

    // DivisionForDivisionStrategy
    if (!isEmpty(divisionInitiative)) {
        filterFuncs.push((i: any) => (i?.DivisionId ? [i.DivisionId] : (i?.DivisionForDivisionStrategy || [])).some((v: string) => some(divisionInitiative, { Id: v })))
    }

    if (!isEmpty(editableFields)) {
        editableFields.forEach((i: any) => {
            const filterValue = filterValues[i.Name] || null
            if (isEmpty(filterValue)) {
                return false
            }
            const ids = filterValue.map((i: any) => i.Id)
            filterFuncs.push((item: Record<string, any>) => intersection(item?.EditableFieldOptionIds || [], ids).length > 0)
        })
    }
    if (!isEmpty(ResourcingStrategy)) {
        filterFuncs.push((i: any) => i?.ChangeResourcesLevel.includes(ResourcingStrategy.level))
    }
    return filterFuncs
}
/*
export const filterInitiatives = (items: Array<Record<string, any>>, filterValues: Record<string, any>, editableFields: Array<Record<string, any>>) =>
    items.filter((item) => initiativesFilters(filterValues, editableFields).every((f) => f(item)))
*/
const numberOfCustomersValues = {
    '<100': 1,
    '<500': 2,
    '<1,000': 3,
    '<5,000': 4,
    '<10,000': 5,
    '<50,000': 6,
    '<1 million': 7,
    0: 0,
}

const doesCustomerCareValues = {
    No: 0,
    Yes: 1,
    Neutral: 2,
}

const positiveNegativeValues = {
    Positive: 0,
    Negative: 1,
    Neutral: 2,
}

export const customerFilters = (filterValues: Record<string, any>) => {
    const {
        numberOfCustomers,
        customerTypes,
        doesCustomerCare,
        customerImpactLevel,
        positiveNegative
    } = filterValues || {}

    const filterFuncs: Array<any> = []
    if (!isEmpty(customerImpactLevel)) {
        filterFuncs.push((i: any) => customerImpactLevel.includes(i?.ImpactMagnitude || -1))
    }
    if (!isEmpty(positiveNegative)) {
        // @ts-ignore
        const _val = positiveNegative.map((i: string) => positiveNegativeValues[i] || -1)
        filterFuncs.push((i: any) => _val.includes(i?.PositiveNegative || -1))
    }
    if (!isEmpty(doesCustomerCare)) {
        // @ts-ignore
        const _val2 = doesCustomerCare.map((i: string) => doesCustomerCareValues[i] || -1)
        filterFuncs.push((i: any) => _val2.includes(i?.Care || -1))
    }
    if (!isEmpty(numberOfCustomers)) {
        // @ts-ignore
        const _val3 = numberOfCustomers.map((i: string) => numberOfCustomersValues[i] || -1)
        filterFuncs.push((i: any) => _val3.includes(i?.Care || -1))
    }
    if (!isEmpty(customerTypes)) {
        // ImpactCustomerTypes
        filterFuncs.push((i: any) => (i?.ImpactCustomerTypes || []).some((v: string) => customerTypes.includes(v)))
    }

    return filterFuncs
}

export const impactFilters = (
    filterValues: Record<string, any>,
    filteringImpactType: string = 'Employee'
) => {
    const {
        geography,
        activities,
        stakeholders,
        partnerStakeholders,
        division,
        subDivision,
        team,
        impactType,
        partnerRegions,
        partnerNetworkOffices,
        partnerDistrictOffices,
        partnerOrgNames,
        partnerOrgTeams,
        partnerOrgTypes,
        changeImpactNames,
        partnerImpactScales,
        impactLevel,
        customerImpactLevel,
        partnerImpactLevel,
        tags,
        EmpGeographyRegion,
        EmpGeographyNetworkOffice,
        EmpGeographyDistrictOffice
    } = filterValues || {}
    const filterFuncs: Array<any> = []

    const isEmployee = filteringImpactType === 'Employee'
    const isPartner = filteringImpactType === 'Partner'
    const isProcess = filteringImpactType === 'Process'

    if (!isEmpty(impactType)) {
        filterFuncs.push((i: any) => impactType.includes(i?.ImpactType))
    }

    if (!isEmpty(activities)) {
        filterFuncs.push((i: any) => (i?.Activities || []).some((v: string) => activities.includes(v)))
    }

    if (isEmployee || isProcess) {
        if (!isEmpty(stakeholders)) {
            filterFuncs.push((i: any) => (i?.Stakeholders || []).some((v: string) => stakeholders.includes(v)))
        }

        if (!isEmpty(division)) {
            filterFuncs.push((i: any) => {
                const item = i?.Division || i?.DivisionNames || []
                if (isEmpty(item)) {
                    return false
                }
                return item.some((v: any) => some(division, { Name: v }))
            })
        }
        if (!isEmpty(subDivision)) {
            filterFuncs.push((i: any) => {
                const item = i?.SubDivision || i?.SubDivisionNames || []
                if (isEmpty(item)) {
                    return false
                }
                return item.some((v: string) => some(subDivision, { Name: v }))
            })
        }
        if (!isEmpty(team)) {
            filterFuncs.push((i: any) => {
                const item = i?.Team || i?.TeamNames || []
                if (isEmpty(item)) return false
                return item.some((v: string) => some(team, { Name: v }))
            })
        }

        if (isProcess) {
            if (!isEmpty(changeImpactNames)) {
                filterFuncs.push((i: any) => changeImpactNames.includes(i?.ChangeImpactName || i?.ImpactName))
            }
        }
    }

    if (isEmployee) {
        if (!isEmpty(geography)) {
            filterFuncs.push((i: any) => (i?.Geographies || []).some((v: string) => geography.includes(v)))
        }

        if (!isEmpty(EmpGeographyRegion)) {
            const regions = (EmpGeographyRegion || []).map((i: BaseModel) => i.Name)
            filterFuncs.push((i: any) => (i?.EmpGeographyRegion || []).some((v: string) => regions.includes(v)))
        }

        if (!isEmpty(EmpGeographyNetworkOffice)) {
            const regions = (EmpGeographyNetworkOffice || []).map((i: BaseModel) => i.Name)
            filterFuncs.push((i: any) => (i?.EmpGeographyNetworkOffice || []).some((v: string) => regions.includes(v)))
        }

        if (!isEmpty(EmpGeographyDistrictOffice)) {
            const regions = (EmpGeographyDistrictOffice || []).map((i: BaseModel) => i.Name)
            filterFuncs.push((i: any) => (i?.EmpGeographyDistrictOffice || []).some((v: string) => regions.includes(v)))
        }
    }

    if (isPartner) {
        if (!isEmpty(partnerImpactScales)) { // PartnerImpactScaleId
            filterFuncs.push((i: any) => partnerImpactScales.includes(i?.PartnerImpactScaleId))
        }

        if (!isEmpty(partnerStakeholders)) {
            filterFuncs.push((i: any) => (i?.PartnerStakeholder || []).some((v: string) => partnerStakeholders.includes(v)))
        }

        if (!isEmpty(partnerRegions)) {
            const regions = (partnerRegions || []).map((i: BaseModel) => i.Name)
            filterFuncs.push((i: any) => (i?.Region || []).some((v: string) => regions.includes(v)))
        }

        if (!isEmpty(partnerDistrictOffices)) {
            const districtOffice = (partnerDistrictOffices || []).map((i: BaseModel) => i.Name)
            filterFuncs.push((i: any) => (i?.DistrictOffice || []).some((v: string) => districtOffice.includes(v)))
        }

        if (!isEmpty(partnerNetworkOffices)) {
            const networkOffice = (partnerNetworkOffices || []).map((i: BaseModel) => i.Name)
            filterFuncs.push((i: any) => (i?.NetworkOffice || []).some((v: string) => networkOffice.includes(v)))
        }

        if (!isEmpty(partnerOrgNames)) {
            const names = (partnerOrgNames || []).map((i: BaseModel) => i.Name)
            filterFuncs.push((i: any) => (i?.PartnerName || (i?.PartnerNames || [])).some((v: string) => names.includes(v)))
        }

        if (!isEmpty(partnerOrgTeams)) {
            const names = (partnerOrgTeams || []).map((i: BaseModel) => i.Name)
            filterFuncs.push((i: any) => (i?.PartnerOrgTeamIds || (i?.PartnerTeam || [])).some((v: string) => names.includes(v)))
        }

        if (!isEmpty(partnerOrgTypes)) {
            const names = (partnerOrgTypes || []).map((i: BaseModel) => i.Name)
            filterFuncs.push((i: any) => (i?.PartnerType || (i?.PartnerTypes || [])).some((v: string) => names.includes(v)))
        }

        if (!isEmpty(partnerImpactLevel)) {
            const levels = partnerImpactLevel.map((i: Record<string, any>) => ''+i?.Level)
            filterFuncs.push((i: any) => levels.includes(''+(i?.ImpactLevel||'-0-0-00s')))
        }
    }

    // Tag  tags
    if (!isEmpty(tags)) {
        const items = tags.map((i: Record<string, any>) => i?.Id)
        filterFuncs.push((i: any) => (i?.Tag || []).some((v: string) => items.includes(v)))
    }


    if (!isEmpty(impactLevel)) {
        const levels = impactLevel.map((i: Record<string, any>) => ''+i?.Level)
        filterFuncs.push((i: any) => levels.includes(''+(i?.ImpactLevel||'')))
    }

    return filterFuncs
}
export const filterImpact = (items: Array<Record<string, any>>, filterValues: Record<string, any>) =>
    items.filter((item) => impactFilters(filterValues).every((f) => f(item)))
