import React, { useEffect, useMemo, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import MEditableRow from './MEditableRow'
import EnhancedTableHead, { ColumnHeader, Order } from './EnhancedTableHead'
import { find, isArray, isEmpty, unset, zipObject } from 'lodash'
import MLinearProgress from 'components/@material-extend/MLinearProgress'
import NothingToShowYet from 'components/NothingToShowYet'
import ConfirmDeleteItemTable from './ConfirmDeleteItemTable'
import { getComparator, stableSort } from './utils'
import useClosableSnackbar from 'hooks/useClosableSnackbar'
import { getErrorMsg } from 'utils/errorReport';
import { isNewInitiative } from 'utils/iniatives';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core'

import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

export interface MTableProps {
    data: any ///(order?:Order, field?:string) => any[] | Promise<any> | Record<string, any>[] | Array<any>
    saveItem?: (item: Record<string, any>, refetch?: boolean) => Promise<any>
    deleteItem?: (item: Record<string, any>) => Promise<any>
    columns: ColumnHeader[]
    uniqueId?: string
    newItemDefault?: Record<string, any>
    addNew?: boolean
    selectable?: boolean
    selectedRow?: any
    onSelect?: (item: Record<string, any>) => void
    onDeselect?: () => void
    reload?: number | string
    localSort?: boolean
    failedDeleteEntityMessage?: (item: Record<string, any>) => string
    draggable?: boolean
}

const MEditableTable = ({
    data,
    columns,
    uniqueId = 'Id',
    saveItem,
    deleteItem,
    newItemDefault = {},
    addNew = true,
    selectable = false,
    onSelect = () => {},
    onDeselect = () => {},
    selectedRow = {},
    reload = -1,
    localSort = true,
    draggable = false,
    failedDeleteEntityMessage,
}: MTableProps) => {
    const [editableItemId, setEditableItemId] = useState<any>(null)

    const [order, setOrder] = React.useState<Order>('asc')
    const [orderBy, setOrderBy] = React.useState<string>(uniqueId)
    const [items, setItems] = React.useState<Record<string, any>[]>([])
    const [loading, setLoading] = useState(false)
    const [deleteEntity, setDeleteEntity] = useState<Record<string, any>>({})
    const [isDeleting, setIsDeleting] = useState(false)

    const { closableEnqueueSnackbar } = useClosableSnackbar()

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const loadData = () => {
        if (isArray(data)) {
            setItems(data)
            return
        }
        setLoading(true)
        const result = data(order, orderBy)
        if (!isArray(result)) {
            // @ts-ignore
            result.then((data) => setItems(data)).finally(() => setLoading(false))
            return
        }
        setLoading(false)
        // @ts-ignore
        setItems(result)
    }

    // eslint-disable-next-line
    useEffect(loadData, [reload])

    useEffect(() => {
        if (localSort) {
            setItems(stableSort(items, getComparator(order, orderBy)))
        } else {
            loadData()
        }
        // eslint-disable-next-line
    }, [order, orderBy])
    /// (isArray(data) && ((selectedRow[uniqueId]||null) || data))

    const showMessage = (msg: string, type: any = 'success') => {
        closableEnqueueSnackbar(msg as string, type)
    }

    const onRowDelete = (item: Record<string, any>) => {
        if (deleteItem !== undefined) {
            setIsDeleting(true)
            deleteItem(item)
                .then(() => {
                    showMessage('Entity successfully deleted')
                    loadData()
                })
                .catch((error: any) => {
                    console?.log(error)
                    showMessage(failedDeleteEntityMessage !== undefined ? failedDeleteEntityMessage(item) : getErrorMsg(error,'Failed to delete Entity'), 'error')
                })
                .finally(() => {
                    setIsDeleting(false)
                    onDeselect()
                })
        }
    }

    const newItemHandler = (): Record<string, any> => ({
        ...zipObject(columns.map((i: ColumnHeader) => i.field)),
        ...newItemDefault,
        ...(draggable ? { DisplayOrder: items.length } : {})
    })

    const onRowSave = (item: Record<string, any>) => {
        if (saveItem === undefined) return

        if (isNewInitiative(item)) { //(item[uniqueId] || 0) <= 0) {
            unset(item, uniqueId)
            item = { ...newItemHandler(), ...item }
        }

        saveItem(item)
            .then((data: Record<string, any>) => {
                const { message } = data || {}
                if (!(message||'successfully')?.toLowerCase()?.includes('successfully')) {
                    showMessage(getErrorMsg(message, 'Failed to save Entity'), 'error')
                    return
                }
                showMessage('Entity successfully saved')
                loadData()
                setEditableItemId(null)
                onDeselect()
            })
            .catch((err: any) => {
                console?.log(err)
                setEditableItemId(editableItemId)
                showMessage(getErrorMsg(err, 'Failed to save Entity'), 'error')
            })
            //.finally(() => onDeselect())
    }

    const columnsParsed = useMemo(() => columns.map((column: ColumnHeader) => ({ editable: true, ...column })), [columns])

    const editable = saveItem !== undefined
    const deletable = deleteItem !== undefined


    const renderTable = () => (
        <Table stickyHeader sx={{ padding: '0 0 0 0', margin: '0 0 0 0' }} size='small'>
            <EnhancedTableHead
                columns={columnsParsed}
                addNew={addNew && saveItem !== undefined}
                onRequestSort={handleRequestSort}
                order={order}
                addEmptyActionCell={deletable || editable}
                orderBy={orderBy}
                addAction={() => {
                    if (find(items, { [uniqueId]: -1 }) !== undefined) {
                        return
                    }
                    setItems([{ ...newItemHandler(), [uniqueId]: -1 }, ...items])
                    setEditableItemId(-1)
                    onDeselect()
                }}
            />
            <TableBody>
                {loading ? (
                    <TableRow>
                        <TableCell colSpan={200}>
                            <MLinearProgress
                                color='error'
                                sx={{
                                    width: '90%',
                                    margin: '0 5%',
                                }}
                            />
                        </TableCell>
                    </TableRow>
                ) : (
                    items.map((item: Record<string, any>, index) => (
                        <MEditableRow
                            isEditable={editableItemId === (item[uniqueId] || '')}
                            row={item}
                            index={index}
                            uniqueKey={(""+item[uniqueId]) + index}
                            key={(""+item[uniqueId]) + index}
                            columns={columnsParsed}
                            draggable={draggable}
                            editable={editable}
                            onEdit={(val: Record<string, any>) => {
                                if (isEmpty(val) && (item[uniqueId] || null) === -1) {
                                    setItems(items.filter((i) => i[uniqueId] !== -1))
                                }
                                setEditableItemId(val[uniqueId] || '')
                            }}
                            onSave={(item) => {
                                onRowSave(item)
                                ///onDeselect()
                            }}
                            onDelete={(item) => {
                                setDeleteEntity(item)
                                onDeselect()
                            }}
                            canDelete={deletable}
                            selectable={selectable}
                            selected={selectable && (item[uniqueId] || null) === (selectedRow[uniqueId] || null)}
                            onSelect={onSelect}
                        />
                    ))
                )}
                {!loading && isEmpty(items) && (
                    <TableRow>
                        <TableCell colSpan={200}>
                            <NothingToShowYet />
                        </TableCell>
                    </TableRow>
                )}

            </TableBody>
        </Table>
    )

    return (
            <TableContainer sx={{ maxHeight: 500 }}>
                <ConfirmDeleteItemTable
                    onDelete={onRowDelete}
                    isDeleting={isDeleting}
                    handleClose={() => setDeleteEntity({})}
                    item={deleteEntity}
                />
                {/*
                // @ts-ignore */}
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={(event: any) => {
                        const {active, over} = event;

                        if (!active || !over) {
                            return;
                        }

                        if (active?.id !== over?.id) {
                            setItems((items) => {
                                const arr =  arrayMove(items, active.id - 1, over.id -1)
                                    .map((i, index) => ({...i, DisplayOrder: index}));
                                if (saveItem !== undefined) {
                                    Promise
                                        .allSettled(arr.map((i, index) => saveItem(i, arr.length === index + 1).catch((err) => console?.log(err))))
                                        .finally(() => showMessage('Order saved!'))
                                }
                                return arr
                            });
                        }
                    }}
                >
                    <SortableContext
                        items={items.map((i, index) => ({ id: index + 1 }))}
                        strategy={verticalListSortingStrategy}
                    >
                        {renderTable()}
                    </SortableContext>
                </DndContext>
            </TableContainer>

    )
}

export default MEditableTable
